<script>
import WorkspaceInviteCard from '@/components/general/WorkspaceInviteCard'
export default {
  name: 'ModalInviteList',
  components: { WorkspaceInviteCard },
  data () {
    return {
      anyAccepted: false,
      inviteList: []
    }
  },
  computed: {
    pendingInvites () {
      return (this.$store.getters.getUser && this.$store.getters.getUser.pendingInvites) || []
    },
    shouldRemindLater () {
      return !this.$store.getters.getWorkspaceInvitesRemindLater
    }
  },
  watch: {
    getUserInfo () {
      this.inviteList = this.pendingInvites.map((invite) => {
        return { ...invite, accept: true }
      })
    }
  },
  methods: {
    close (suppressToast = false) {
      this.$store.commit('setWorkspaceInvitesRemindLater', true)
      if (!suppressToast) {
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'info',
          title: this.$t('remind.workspace.invites.later')
        })
      }
    },
    submit () {
      this.inviteList.forEach((invite, index, array) => {
        const postPush = index + 1 === array.length
        if (invite.accept) {
          this.anyAccepted = invite
          this.acceptInvite(invite, postPush)
        } else {
          this.refuseInvite(invite, postPush)
        }
      })
    },
    acceptInvite (invite, postPush = false) {
      this.$store.dispatch('attemptAcceptWorkspaceInvite', invite.id).then(() => {
        postPush && this.postSubmit()
      })
    },
    refuseInvite (invite, postPush = false) {
      this.$store.dispatch('attemptRefuseWorkspaceInvite', invite.id).then(() => {
        postPush && this.postSubmit()
      })
    },
    postSubmit () {
      this.$store.dispatch('attemptGetUserWorkspaces').then((data) => {
        this.anyAccepted && this.$store.commit('setSelectedWorkspace', data.find(item => item.id === this.anyAccepted.workspaceId))
        this.anyAccepted && this.$router.push({ name: this.selectedWorkspace.type + '.home.user.contents', params: { newToWorkspace: this.anyAccepted && 'COMPLETED' } })
        this.close(true)
      })
    }
  }
}
</script>
<template>
  <v-dialog :value="pendingInvites.length && shouldRemindLater" persistent width="600px">
    <v-card class="modal-invite-list--container">
      <div class="modal-invite-list--header__wrapper">
        <h5 class="h7">{{ $t('modal.invites.list:title') }}</h5>
        <v-btn icon @click="close(false)" color="#1200D3"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div class="modal-invite-list--body">
        <p class="subtitle--1">{{ $t('modal.invites.list:description') }}</p>
        <div class="modal-invite-list--header lato bold">
          <p class="bottom-row">{{$t('modal.invites.list:header.left')}}</p>
          <p class="bottom-row-right">{{$t('modal.invites.list:header.right')}}</p>
        </div>
        <div class="modal-invite-list--list__wrapper" v-if="inviteList.length > 0">
          <template v-for="invite in inviteList">
            <workspace-invite-card :key="invite.id" :invite="invite" v-model="invite.accept"/>
          </template>
        </div>
        <p class="subtitle--1">{{ $t('modal.invites.list:description.bottom') }}</p>
        <div class="modal-invite-list--actions">
          <v-btn class="bold" outlined color="#1200D3" @click="close(false)">{{ $t('modal.invite.list:remind.later') }}</v-btn>
          <v-btn class="bold" dark color="#1200D3" @click="submit">{{ $t('modal.invite.list:confirm.invites') }}</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-invite-list--container {
  padding: 17px 20px;
  text-align: left;

  .modal-invite-list--header__wrapper {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .modal-invite-list--body {
    .modal-invite-list--list__wrapper {
      margin: 24px 0;
    }
    .subtitle--1 {
      color: $neutral-medium
    }
    .modal-invite-list--actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      .v-btn:nth-child(1) {
        margin-right: 8px;
      }
    }
    .modal-invite-list--header {
      height: 10px;
      position: relative;
      .bottom-row-right {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
</style>
