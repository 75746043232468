<script>
import ConfirmationModal from '@/components/general/ConfirmationModal'
export default {
  name: 'ModalReactivate',
  components: { ConfirmationModal },
  props: {
    description: {
      type: String
    },
    buttonText: {
      type: String
    },
    details: {
      type: Object
    }
  },
  methods: {
    openConfirmationModal () {
      this.showConfirmationModal = true
    },
    closeConfirmationModal () {
      this.$store.commit('setSelectedWorkspace', this.$store.getters.getUserWorkspaces.find(workspace => {
        return workspace.id === this.$store.getters.getIndividualWorkspace.id
      }))
    },
    confirmReactivate () {
      this.$emit('reactivate')
    }
  }
}
</script>
<template>
  <div class="modal-reactivate--container">
    <confirmation-modal
      :show="true"
      :title="$t('workspace.reactivate.confirmation:title')"
      :subtitle="$t('workspace.reactivate.confirmation:subtitle')"
      :description="$t('workspace.reactivate.confirmation:description', { ...details })"
      :btnConfirmText="false"
      :confirmText="$t('workspace.reactivate.confirmation:confirmText')"
      :cancelText="$t('global:cancel')"
      :maxWidth="620"
      @cancel="closeConfirmationModal"
      @confirm="confirmReactivate"
    />
  </div>
</template>
<style lang="scss">
.modal-reactivate--container {
  position: absolute;
  top: 70px;
  left: 0;
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px);
  width: 100vw;
  background: rgba(0, 0, 0, .7);
  z-index: 150;
}
</style>
