<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'WorkspaceInviteCard',
  props: {
    invite: {
      type: Object,
      required
    },
    value: {
      type: Boolean
    }
  },
  data () {
    return {
      mutableValue: true
    }
  },
  watch: {
    value () {
      this.mutableValue = this.value
    }
  },
  methods: {
    changeValue (value) {
      this.mutableValue = value
      this.$emit('input', this.mutableValue)
      this.$emit('change', this.mutableValue)
    }
  },
  created () {
    this.mutableValue = this.value
  }
}
</script>
<template>
  <div class="workspace-invite-card--container">
    <div class="workspace-invite-card--info">
      <v-img max-height="40" class="round" max-width="40" :src="invite.workspaceImageUrl ? changeThumbToSize(invite.workspaceImageUrl, 'avatar') : defaultAvatarUrl('business')"/>
      <div class="workspace-invite-card--name__wrapper">
        <h5 class="h7">{{ invite.workspaceName }}</h5>
        <p class="subtitle--2">{{ $t(`workspace.type:${invite.workspaceType}`) }}</p>
      </div>
    </div>
    <div class="workspace-invite-card--actions">
      <v-switch
        @change="changeValue"
        v-model="mutableValue"
      ></v-switch>
    </div>
  </div>
</template>
<style lang="scss">
.workspace-invite-card--container {
  display: flex;
  background-color: $neutral-lightest;
  border: 1px solid $neutral-light;
  border-radius: 4px;
  justify-content: space-between;
  padding: 15px 17px;
  .workspace-invite-card--info {
    display: flex;
    align-items: center;
    .subtitle--2 {
      margin: 0;
    }
    .v-image {
      margin-right: 12px;
    }
  }
  .workspace-invite-card--actions {
    display: flex;
    align-items: center;
    height: 40px;
    .v-btn:nth-child(1) {
      margin-right: 8px;
    }
    .v-input .v-input__slot {
      margin-bottom: 4px;
    }
  }
}
</style>
